<template>
  <div class="app-container">
    <div class="content1" v-if="flag === 'prospect'">
      <span class="title">欢迎咨询，上海中科新生命为您服务</span>
      <el-form
        class="form"
        ref="form"
        @submit.native.prevent="submitForm"
        :model="form"
        :rules="rules"
        size="medium"
        label-position="top"
      >
        <el-form-item label="姓名" prop="name">
          <el-input
            v-model.trim="form.name"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="单位" prop="company">
          <el-input
            v-model.trim="form.company"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
					<el-input v-model.trim="form.email" placeholder="请输入" clearable></el-input>
				</el-form-item>
        <el-form-item label="手机" prop="phone">
          <el-input
            v-model.trim="form.phone"
            @input="handleCheck"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="短信验证码" prop="code">
          <el-row :gutter="12">
            <el-col :span="12">
              <el-input
                v-model.trim="form.code"
                maxlength="4"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-col>
            <el-col :span="12">
              <el-button
                type="primary"
                plain
                :disabled="btnDisabled"
                @click="sendCode"
                :style="{ width: '100%' }"
                >{{ btnText }}</el-button
              >
            </el-col>
          </el-row>
          <!-- <el-input v-model="code" style="width: 120px;" />    -->
        </el-form-item>
        <el-form-item label="意向说明" prop="description">
          <el-input
            type="textarea"
            :rows="4"
            maxlength="500"
            show-word-limit
            placeholder="请输入"
            v-model.trim="form.description"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            native-type="submit"
            type="primary"
            @click="submitForm('form')"
            :style="{ width: '100%' }"
            >提交合作意向</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="content2" v-if="flag === 'success'">
      <i class="el-icon-circle-check"></i>
      <span class="tips">提交成功，工作人员将尽快联系您！</span>
    </div>
    <div class="content3" v-if="flag === 'error'">
      <i class="el-icon-warning-outline"></i>
      <span class="tips">服务端异常，请稍后重试！</span>
      <el-button type="primary" click="handleReturn">返回</el-button>
    </div>
  </div>
</template>

<script>
import { check, submit, sendMessage } from "@/api/prospect/index";
export default {
  name: "prospect",
  data() {
    return {
      flag: "prospect",
      code: "",
      btnDisabled: true,
      btnText: "发送验证码",
      timer: null, // 定时器实例
      form: {
        name: "",
        company: "",
        phone: "",
        code: "",
        email: "",
        description: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
        ],
        company: [
          {
            required: true,
            message: "请输入单位",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入手机",
            trigger: "blur",
          },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号格式不对，请重新输入",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "请输入短信验证码",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "请输入邮箱",
            trigger: "blur",
          },
          {
            pattern:
              /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
            message: "邮箱格式不对，请重新输入",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    // 校验手机号唯一性
    handleCheck() {
      const phoneReg = /^1[3456789]\d{9}$/;
      if (
        this.form.phone &&
        phoneReg.test(this.form.phone) &&
        this.form.phone.length === 11
      ) {
        const params = {
          phone: this.form.phone,
        };
        check(params)
          .then((data) => {
            if (data) {
              this.$message({
                type: "warning",
                message: "手机号已存在，请重新输入！",
              });
            } else {
              this.btnDisabled = false;
            }
          })
          .catch(() => {});
      } else {
        this.btnDisabled = true;
      }
    },
    // 触发发送短信验证码
    sendCode() {
      if (this.btnDisabled) return;
      console.log(this.btnDisabled);
      // 发送验证码
      const params = {
        phone: this.form.phone,
      };
      sendMessage(params)
        .then((data) => {
          if (data) {
            this.$message({
              type: "success",
              message: "验证码已发送至" + this.form.phone,
            });
            // 倒计时
            let count = 60; // 倒计时时间
            this.btnDisabled = true;
            this.timer = setInterval(() => {
              count--;
              this.btnText = count + "秒后重试";
              if (count === 0) {
                clearInterval(this.timer);
                this.btnDisabled = false;
                this.btnText = "发送验证码";
              }
            }, 1000);
          } else {
            this.$message({
              type: "error",
              message: "短信发送失败，请重新获取！",
              duration: 5000,
            });
          }
        })
        .catch(() => {});
    },
    // 提交
    submitForm(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          const params = {
            name: this.form.name,
            company: this.form.company,
            phone: this.form.phone,
            code: this.form.code,
            email: this.form.email,
            description: this.form.description,
          };
          submit(params)
            .then(async (data) => {
              if (data) {
                this.$message({
                  type: "success",
                  message: "提交成功，我们将尽快联系您！",
                  duration: 5000,
                });
                setInterval(() => {
                  location.reload();
                }, 5000);
                this.$refs[form].resetFields();
                this.btnDisabled = false;
              } else {
                this.$message({
                  type: "error",
                  message: "系统异常，请重新提交！",
                  duration: 5000,
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          this.$message({
            type: "error",
            message: "系统故障，请稍后再试！",
            duration: 5000,
          });
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.app-container {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 4vh 0.8rem;
  box-sizing: border-box;
  .content1 {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    .title {
      font-size: 0.52rem;
    }

    .form {
      margin-top: 2vh;
    }
  }

  .content2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .el-icon-circle-check {
      font-size: 2rem;
      color: #67c23a;
    }

    .tips {
      margin-top: 4vh;
      font-size: 0.4rem;
    }
  }

  .content3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .el-icon-warning-outline {
      font-size: 2rem;
      color: #f56c6c;
    }

    .tips {
      margin-top: 4vh;
      font-size: 0.4rem;
    }

    .el-button {
      margin-top: 4vh;
    }
  }
}
</style>

<style lang="scss">
#app {
  min-width: unset;
  height: 100%;
  width: 100%;
}
</style>
