import request from "@/utils/request";

// 校验手机号唯一性
export function check(data) {
  return request({
    url: "/apt-user/pub/check",
    method: "post",
    data,
  });
}

// 提交
export function submit(data) {
  return request({
    url: "/apt-user/pub/submit",
    method: "post",
    data,
  });
}

// 发送短信
export function sendMessage(data) {
  return request({
    url: "/apt-user/pub/sendMessage",
    method: "post",
    data,
  });
}